<template>
  <div class="quebanksys-mobile">
    <div class="number1">
      <div>
        <p class="box-title">题库体系</p>
        <p>
          景翔经历20多年管理体系落地的探索，首创了<span>“题库体系”</span>。我们的专家将帮助您将庞大繁杂的体系文件转化到<span>“落地格式”</span>上，并依托景翔平台实施无死角落地。题库体系包括了各类认证体系题库、部门和个人(岗位)题库，解决了员工难以掌握文件总量中哪些内容与自己有关的难题，也就排除了文件难以落地的关键障碍。
        </p>
      </div>
    </div>
    <div class="number2">
      <p class="box-title">落地检查题库特点</p>
      <div class="feature" v-for="(item, index) in featureList" :key="index">
        <div>
          <img :src="item.img" alt="" />
          <span>{{ item.title }}</span>
        </div>
        <p>
          {{ item.p }}
        </p>
      </div>
    </div>
    <div class="number3">
      <p class="box-title">题库体系构成</p>
      <div class="contain">
        <img src="../../assets/mobileImgs/quebanksys8.png" alt="" />
        <div class="bigtitle">
          <span>标准通用题库</span>
          <span>专题题库</span>
          <span>审核问题题库</span>
          <span>测试题库</span>
          <span>题库体系构成</span>
          <span>例行工作检查题库</span>
          <span>体系文件落地检查题库</span>
        </div>
      </div>
      <div class="text" v-for="(item, index) in systemList" :key="index">
        <p :style="{ background: item.background }">{{ item.title }}</p>
        <p>
          {{ item.p }}
        </p>
      </div>
    </div>
    <div class="number4">
      <p class="box-title">能解决什么问题?</p>
      <div class="contain">
        <img src="../../assets/mobileImgs/quebanksys9.png" alt="" />
        <div>
          <p>精准减负</p>
          <p>文件越多，员工越困惑，题库可以帮员工做减法，精准把握关键内容</p>
        </div>
        <div>
          <p>精准提炼</p>
          <p>
            文件方式参差不齐，表述复杂，造成理解和落地困难，题库可以帮您做提炼
          </p>
        </div>
        <div>
          <p>精准分类</p>
          <p>
            文件活动和输出没有分类、没有工作标准，员工分不清楚工作重点，题库可以轻松解决这一问题
          </p>
        </div>
        <div>
          <p>精准培训</p>
          <p>目前90%的各种培训是无效的，聚焦在题库范围内的培训才是有的放矢的</p>
        </div>
        <div>
          <p>全面落地</p>
          <p>
            内审周期长，没有时间做准备，无法做到真正落地，题库可以帮助增加审核频次、远程审核和突击审核。
          </p>
        </div>
      </div>
    </div>
    <div class="number5">
      <div>
        <div class="contain">
          <p>文件到题库的转换</p>
          <p>
            题库体系建立是一项非常专业的工作，工作量大，企业自身难以胜任，企业可用很低的成本委托我公司承担这项工作。
          </p>
          <div class="img">
            <img src="../../assets/mobileImgs/quebanksys11.png" alt="" />
          </div>
        </div>
      </div>
      <div>
        <div class="contain">
          <p>题库体系导入云体系平台</p>
          <p>
            可通过平台系统进行内审、日常检查、学习，专项模块实现题库的智能提取与应用。可以抽取标准体系题库，也可以抽取企业自建题库，
          </p>
          <div class="img">
            <img src="../../assets/mobileImgs/quebanksys12.png" alt="" />
          </div>
        </div>
      </div>
      <div>
        <div class="contain">
          <p>常规标准审核界面</p>
          <p>生成<span style="color: #1a70c6">审核计划</span></p>
          <div class="img">
            <img src="../../assets/mobileImgs/quebanksys13.png" alt="" />
          </div>
        </div>
      </div>
      <div>
        <div class="contain">
          <p>学习和业务测评界面</p>
          <p>
            基于不同类型的题库，可以快速针对不同岗位、不同职能生成个性化学习和业务测试卷。
          </p>
          <div class="img">
            <img src="../../assets/mobileImgs/quebanksys14.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      featureList: [
        {
          title: "文件萃取",
          img: require("../../assets/mobileImgs/quebanksys7.png"),
          p: "从庞大和复杂的文件体系中识别和抽取各部门和各岗位的内容，并与移动端连接，便于执行。",
        },
        {
          title: "专业性强",
          img: require("../../assets/mobileImgs/quebanksys3.png"),
          p: "将各种各样的文件格式和表达方式转化到统一的“落地格式”上，需要很强的专业性。",
        },
        {
          title: "知识萃取",
          img: require("../../assets/mobileImgs/quebanksys4.png"),
          p: "题库体系涵盖了文件体系的全部内容，可以转化为“岗位学习内容”、“测试题”和“应知应会”",
        },
        {
          title: "持续更新",
          img: require("../../assets/mobileImgs/quebanksys5.png"),
          p: "根据文件体系的变化，题库体系可委托平台进行实时更新，确保与作业文件内容的一致性。",
        },
        {
          title: "高效检查",
          img: require("../../assets/mobileImgs/quebanksys6.png"),
          p: "题库体系可自动生成内审检查表、工作检查表，降低了审核员和检查人员的专业门槛。",
        },
      ],
      systemList: [
        {
          title: "标准通用题库",
          p: "根据ISO 9001、ISO 14001、ISO45001、IATF16949等常规标准生成的题库。",
          background: "#24A5DE",
        },
        {
          title: "例行工作检查题库",
          p: "针对现场特点专门建立的5S、工艺纪律、安全、环境因素、信息安全等题库。",
          background: "#8DC551",
        },
        {
          title: "体系文件落地检查题库",
          p: "根据企业的管理体系文件转换的落地检查题库。",
          background: "#F4BA19",
        },
        {
          title: "专题题库",
          p: "针对特定管理主题（特种行业、特种设备、风险）专门开发的题库。",
          background: "#36A7BC",
        },
        {
          title: "标准通用题库",
          p: "用于业务测试、应知应会、培训等的题库。",
          background: "#24DEDC",
        },
        {
          title: "审核问题题库",
          p: "汇总内外部历次审核中发现的问题点转化为重点问题检查题库。",
          background: "#873CD4",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.quebanksys-mobile {
  width: 100%;
  font-size: 0.28rem;
  background: #00478d;
  padding-bottom: 1.5rem;
  .box-title {
    width: 4.5rem;
    height: 0.7rem;
    background: url("../../assets/mobileImgs/quebanksys2.png") no-repeat;
    background-size: 100%;
    background-position: bottom;
    text-align: center;
    color: white;
    display: inline-block;
    font-size: 0.32rem;
    line-height: 0.6rem;
    font-weight: bold;
  }
  .number1 {
    position: relative;
    background: url("../../assets/mobileImgs/quebanksys1.png") no-repeat;
    background-size: 100%;
    height: 100vh;
    div {
      position: absolute;
      bottom: 62px;
      background-color: white;
      width: 79%;
      padding: 0 6% 6%;
      margin: 0 4%;
      text-align: center;
      border-radius: 0.3rem;
      p:nth-of-type(2) {
        text-align: start;
        line-height: 0.4rem;
        margin-top: 0.4rem;
        span {
          cursor: default;
          color: #155fc8;
        }
      }
    }
  }
  .number2 {
    background: linear-gradient(to bottom, #fefdfe, #ddeafc);
    margin: 0 4%;
    border-radius: 0.3rem;
    text-align: center;
    padding-bottom: 0.5rem;
    .feature {
      text-align: start;
      padding: 6% 6% 0 6%;
      div {
        display: flex;
        align-items: center;
        span {
          font-size: 0.32rem;
          font-weight: bold;
          color: #4058cf;
        }
        img {
          width: 0.8rem;
          margin-right: 0.25rem;
        }
      }
      p {
        padding-left: 0.8rem;
        line-height: 0.4rem;
      }
    }
  }
  .number3 {
    text-align: center;
    background: white;
    margin: 4%;
    border-radius: 0.3rem;
    .contain {
      position: relative;
      margin-top: 5%;
      img {
        width: 100%;
        margin: 0 auto;
      }
      .bigtitle {
        span {
          position: absolute;
          width: 1.2rem;
          font-size: 0.27rem;
          color: white;
          font-weight: bold;
        }
        span:nth-of-type(1) {
          top: 7%;
          left: 39%;
        }
        span:nth-of-type(2) {
          top: 30%;
          left: 13.5%;
        }
        span:nth-of-type(3) {
          top: 61%;
          left: 14%;
        }
        span:nth-of-type(4) {
          top: 82%;
          left: 39%;
        }
        span:nth-of-type(5) {
          top: 43%;
          left: 37.5%;
          width: 1.5rem !important;
          font-size: 0.32rem !important;
        }
        span:nth-of-type(6) {
          top: 31%;
          left: 67%;
        }
        span:nth-of-type(7) {
          top: 61.5%;
          left: 63%;
        }
      }
    }

    .text {
      background: linear-gradient(to bottom, #fefdfe, #ddeafc);
      border-radius: 0.3rem;
      margin-bottom: 0.1rem;
      text-align: start;
      p:nth-of-type(1) {
        font-size: 0.3rem;
        color: white;
        padding: 2% 6%;
        background: red;
        font-weight: bold;
        width: 3.5rem;
        border-radius: 0 0 0.25rem 0;
      }
      p:nth-of-type(2) {
        padding: 4% 6%;
        line-height: 0.4rem;
      }
    }
  }
  .number4 {
    position: relative;
    background: linear-gradient(to bottom, #fefdfe, #ddeafc);
    margin: 4%;
    border-radius: 0.3rem;
    text-align: center;

    .contain {
      text-align: start;
      margin-top: 10%;
      img {
        width: 50%;
        position: absolute;
        right: 0;
        top: 25%;
      }
      div {
        margin-bottom: 8%;
        p:nth-of-type(1) {
          font-size: 0.32rem;
          width: 2.2rem;
          color: #4058ce;
          height: 0.3rem;
          background-color: #dfecf2;
          border-radius: 50%;
          line-height: 3px;
          font-weight: bold;
          padding-left: 0.4rem;
        }
        p:nth-of-type(2) {
          padding: 2% 0.4rem;
        }
      }
      div:nth-of-type(2) {
        width: 3.7rem;
      }
      div:nth-of-type(3) {
        width: 4.2rem;
      }
    }
  }
  .number5 {
    background: linear-gradient(to bottom, #f9f9f9, #ddeafc);
    margin: 4%;
    text-align: center;
    border-radius: 0.3rem;
    > div {
      border-bottom: 0.05rem solid white;
      margin-top: 8%;
    }
    > div:nth-of-type(4) {
      border-bottom: 0 !important;
      padding-bottom: 1rem;
    }
    .contain {
      text-align: start;
      p:nth-of-type(1) {
        background: url("../../assets/mobileImgs/quebanksys10.png");
        width: 3.5rem;
        height: 0.8rem;
        background-size: 100%;
        line-height: 0.7rem;
        font-size: 0.32rem;
        color: white;
        font-weight: bold;
        padding-left: 0.4rem;
      }
      p:nth-of-type(2) {
        padding-top: 0.2rem;
        margin: 0 0.4rem;
        line-height: 0.45rem;
      }
      .img {
        margin: 0.3rem 0.3rem 0 0.3rem;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>